import ReactDOM from "react-dom/client";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import "./index.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Layout from "./components/shared/Layout";
import AuthProvider, { useAuth } from "./context/authContext";
import { PaginationProvider } from "./context/paginationContext";
import UserManager from "./pages/admin/UserManager";
import ArchivedForms from "./pages/archived-forms";
import CompletedForms from "./pages/completed-forms";
import CompletedForms_old from "./pages/CompletedForms";
import CreateForm from "./pages/CreateForm";
import UploadForm from "./pages/UploadForm";
import Dashboard from "./pages/Dashboard";
import AllForms from "./pages/editable-forms";
import EditableForms from "./pages/EditableForms";
import EditFilledForm from "./pages/EditFilledForm";
import EditForm from "./pages/EditForm";
import FillForm from "./pages/FillForm";
import InspectForm from "./pages/InspectForm";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import Settings from "./pages/settings";
import WorkingGroups from "./pages/working-groups";
const rootElement = document.getElementById("root");

const MainApp = () => {
  const location = useLocation();
  //const from = location.state?.from?.pathname || "/";

  const showNavbar =
    location.pathname !== "/login" && location.pathname !== "/logout";
  const isloggedin = localStorage.getItem("token");

  const { user } = useAuth();

  return (
    <>
      <div className={"flex h-full  "}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Login />} />
          {!isloggedin && (
            <Route
              path="/fill-form/:formQrCode"
              element={<FillForm isloggedin={isloggedin} formQr={null} />}
            />
          )}

          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Layout />}>
            <Route index element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/editable-forms" element={<AllForms />} />
            <Route path="/archived-forms" element={<ArchivedForms />} />
            <Route path="/editable-forms2" element={<EditableForms />} />
            <Route path="/admin/user-manager" element={<UserManager />} />
            <Route path="/create-form" element={<CreateForm />} />
            <Route path="/upload-form" element={<UploadForm />} />
            <Route path="/edit-form/:formId" element={<EditForm />} />
            <Route
              path="/fill-form/:formQrCode"
              element={<FillForm isloggedin={isloggedin} />}
            />

            <Route
              path="/edit-filled-form/:formId"
              element={<EditFilledForm />}
            />
            <Route path="/inspect-form/:formId" element={<InspectForm />} />
            <Route path="/completed-forms" element={<CompletedForms />} />
            <Route path="/completed-forms2" element={<CompletedForms_old />} />
            <Route path="/working-groups" element={<WorkingGroups />} />
          </Route>
        </Routes>
      </div>
    </>
  );
};

const queryClient = new QueryClient();

const AppWrapper = () => (
  <Router>
    <AuthProvider>
      <PaginationProvider>
        <QueryClientProvider client={queryClient}>
          <MainApp />
        </QueryClientProvider>
      </PaginationProvider>
    </AuthProvider>
  </Router>
);

ReactDOM.createRoot(rootElement).render(<AppWrapper />);
